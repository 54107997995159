#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

* {
  box-sizing: border-box;
}
