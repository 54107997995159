.review-module {
  border: 1px solid #333;
  padding: 20px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
}

.review-module h3 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #ffa07a;
  text-align: center;
}

.rating label {
  display: block;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #ddd;
}

.stars {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 10px;
}

.star {
  font-size: 2rem;
  color: #bbb;
  cursor: pointer;
  transition: color 0.3s ease;
}

.star.selected {
  color: #ffa500;
}

.star:hover {
  color: #ffcc00;
}

textarea {
  width: 100%;
  height: 80px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #555;
  resize: none;
  background-color: #333;
  color: #fff;
  margin-top: 10px;
}

textarea::placeholder {
  color: #bbb;
}

.submit-button-rewiev {
  background-color: #ff8353 !important;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #ff7e5f;
}

.success-message {
  color: #4caf50;
  margin-top: 10px;
  text-align: center;
  font-size: 1rem;
}

.close-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-button:hover {
  color: #ff7e5f;
}
