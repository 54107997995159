.lapQuestion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  padding-bottom: 100px;
}

.fullscreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.fullscreenImage {
  max-width: 90%;
  max-height: 90%;
}

.lapHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  background-color: #333;
  color: white !important;
  margin-bottom: 2rem;
  margin-top: 0;
  text-align: center;
  gap: 10px;
}

.lapHeader h4 {
  font-size: 1.5rem;
  color: white !important;
  margin: 0;
}

.lapHeader p {
  font-size: 1rem;
  color: white;
  margin: 0;
}

.lapForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.lapForm label {
  width: 100%;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px !important;
}

.lapForm input {
  width: 100%;
  max-width: 600px !important;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.themeAnswer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.themeAnswer label {
  width: 100%;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.themesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  gap: 5px;
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

.navigationButtons button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navigationButtons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.navigationButtons button:not(:disabled):hover {
  background-color: #0056b3;
}

.submitButton {
  margin-top: 20px;
  padding: 10px 30px;
  font-size: 1.2rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #218838;
}

.lapImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.lapImage {
  width: 100%;
  max-width: 600px;
  height: auto;

  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  object-fit: contain;
  margin-top: 1rem;
}

.solutionTextLapQuestion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  margin-top: 2rem;
  gap: 10px;
}

.solutionTextLapQuestionInnerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  max-width: 600px;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #9aff86;
  gap: 10px;
}

.yourTextLapQuestionInnerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  max-width: 600px;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #333;
  gap: 10px;
  margin: 0;
}

.yourTextLapQuestionInnerDiv p,
h4 {
  color: white;
}

.solutionTextLapQuestionInnerDiv p,
h4 {
  color: black !important;
}

.solutionTextLapQuestion h4 {
  font-size: 1.2rem;
  margin: 0;
}

.selfAnswerButtonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 600px;
}

.selfAnswerButtonDiv button {
  transition: ease-in-out 0.1s;
}

.selfAnswerButtonDiv button:hover {
  scale: 1.1;
}

.incorrectTheme {
  background-color: #333333;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.incorrectTheme p {
  margin: 5px;
}

.incorrectThemeTheme {
  color: white;
  font-size: 1.2rem;
  margin: 20px;
}

.incorrectThemeUserAnswer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  color: red !important;
}

.incorrectThemeUserAnswer strong,
.incorrectThemeAnswer strong {
  margin-right: 10px; /* Add desired space */
}

.incorrectThemeAnswer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgb(0, 201, 0);
  padding: 10px;
  border-radius: 5px;
  color: black !important;
}

.selfAnswerDivOver {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  max-width: 600px;
}

.infoDiv {
  position: absolute;
  right: 0;
  top: 1rem;
  width: auto;
  transition: ease-in-out 0.1s;
}

.infoDiv:hover {
  cursor: pointer;
  scale: 1.1;
}

.infoActiveDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  margin-top: 1rem;
}
