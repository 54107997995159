/* Allgemeines Styling */
body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  scroll-behavior: smooth;
}

/* Hauptcontainer */
.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f9f9f9;
  color: #333;
  text-align: center;
}

/* Navbar */
.navbarLandingPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #333;
  color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  padding: 0 1rem;
  height: 7vh;
  z-index: 10;
}

.navbarLandingPageButtonDiv {
  display: flex;
  gap: 30px;
}

.navbarLandingPageButtonDiv p {
  color: rgb(167, 167, 167);
  cursor: pointer;
  transition: all 0.3s ease;
}

.navbarLandingPageButtonDiv p:hover {
  color: #ff7e5f;
}

.login-highlight {
  color: #ff7e5f !important;
  font-weight: bold;
}

/* Content-Bereich */
.content {
  display: flex;
  width: 100%;
  margin-top: 7vh;
  height: auto;
}

.halfDivLandingPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 93vh;
  position: relative;
  padding: 10px;
}

.halfDivLandingPage100 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 93vh;
  position: relative;
  padding: 10px;
}

.halfDivLandingPage p {
  color: #333;
}

.coloredBackground {
  background-color: #ff7e5f;
}

.halfDivLandingPage img {
  width: 80%;
  height: auto;
}

/* Landing-Button */
.landing-button {
  width: auto;
  padding: 12px;
  margin: 8px 0;
  font-size: 1rem;
  font-weight: bold;
  border: 1px solid #ddd;
  border-radius: 8px;
  color: #ff8a65; /* Button text color */
  background-color: #f9f9f9;
  cursor: pointer;
  transition: all 0.3s ease;
}

.landing-button:hover {
  background-color: #ff8a65; /* Button hover color */
  color: #fff;
}

.landing-button:focus {
  outline: none;
  box-shadow: 0px 0px 4px 2px rgba(255, 138, 101, 0.3);
}

/* Seitenbereiche */
.pageLandingPage {
  height: 93vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pageLandingPage h3 {
  color: #ff7e5f;
  margin-bottom: 10px;
}

.pageLandingPage p {
  color: #555;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.feature-list {
  list-style: none;
  display: flex;
  color: #333;
  flex-direction: column;
  gap: 10px;
}

.feature-list li {
  color: #333;
  background-color: #333;
  padding: 20px;
  border-radius: 5px;
  font-size: 1.1rem;
  text-align: left;
}

/* Beschreibung */
.description {
  text-align: left;
  margin-top: 20px;
  max-width: 80%;
}

.description h3 {
  color: #ff7e5f;
  margin-bottom: 10px;
}

.description p {
  color: #333;
  margin-bottom: 15px;
  line-height: 1.6;
}

.description ul {
  padding-left: 20px;
  margin-bottom: 20px;
}

.description ul li {
  margin-bottom: 10px;
  color: #555;
  font-size: 1rem;
  list-style-type: disc;
}

.cta-button {
  margin-top: 20px;
  padding: 15px 30px;
  background-color: #ff7e5f;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background-color: #e6654f;
  transform: scale(1.05);
}

.dashboardImage {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* Responsive Design für mobile Geräte */
@media screen and (max-width: 800px) {
  /* Content: Spaltenlayout zu Zeilenlayout */
  .landing-container {
    gap: 10px;
  }
  .content {
    flex-direction: column;
    height: auto;
    margin-top: 15vh;
    height: 85vh;
  }

  .halfDivLandingPage,
  .halfDivLandingPage100 {
    width: 100%; /* Jede Seite nimmt die gesamte Breite ein */
    height: 100%; /* Höhe passt sich automatisch an */
  }

  .halfDivLandingPage img {
    width: 100%; /* Logo nimmt volle Breite ein */
    max-width: 300px; /* Maximalgröße für kleinere Geräte */
  }

  .cta-button {
    font-size: 1rem;
    padding: 10px 20px;
    margin-bottom: 40px;
  }

  /* Navbar: Komprimierung */
  .navbarLandingPage {
    flex-direction: column;
    height: 15vh;
    padding: 10px;
  }

  .navbarLandingPage img {
    margin-bottom: 10px;
  }

  .navbarLandingPage p {
    margin: 0;
  }

  .navbarLandingPageButtonDiv {
    flex-direction: column;
    gap: 15px;
  }

  .navbarLandingPageButtonDiv p {
    font-size: 1rem;
  }

  /* Feature-Liste: Zentrieren */
  .feature-list {
    text-align: center;
    padding-left: 0;
  }

  .feature-list li {
    font-size: 1rem;
  }

  /* Seitenbereiche */
  .pageLandingPage {
    flex-direction: column;
    height: auto;
    margin-top: 2rem;
  }

  .pageLandingPage h3 {
    font-size: 1.5rem;
  }

  .pageLandingPage p {
    font-size: 1rem;
    line-height: 1.4;
  }

  .flexReverse {
    flex-direction: column-reverse;
  }
}
