/* progressBar.css */
.progressBarContainer {
  height: 24px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 12px;
  margin: 20px 0;
  position: relative;
  max-width: 600px;
  overflow: hidden;
}

.progressBarOuter {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
}

.progressBarCorrect {
  background-color: #4caf50; /* Grün für richtig beantwortete Fragen */
  transition: width 0.4s ease;
}

.progressBarWrong {
  background-color: #f44336; /* Rot für falsch beantwortete Fragen */
  transition: width 0.4s ease;
}

.progressBarUnanswered {
  background-color: #e0e0de; /* Grau für unbeantwortete Fragen */
  transition: width 0.4s ease;
}

.allAnsweredContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 2rem;
  width: 100%;
  max-width: 600px;
}

.resetButton {
  background-color: red;
  border-radius: 5px;
}
