.categoryList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  max-width: 600px;
  margin-bottom: 50px;
}

.categoryList h2 {
  font-size: 1.8rem;
  margin-top: 1rem;
  padding: 0;
  color: #333;
  text-align: center;
}

.questionDivList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.innerQuestionDivList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 8px;
  padding: 15px;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
  background-color: #333;
}

.innerQuestionDivList:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 20px;
}

.innerQuestionDivList p {
  margin: 0;
  color: #fff;
}

.singleQuestionDivList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.singleQuestionListQuestion {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px !important;
}

.singleQuestionListAnswer {
  font-size: 1rem;
  font-weight: 400;
  color: black !important; /* Dezentes Grün für korrekte Antwort */
  background-color: #fff; /* Heller Grünton für Hintergrund */
  padding: 8px;
  border-radius: 5px;
  width: 100%;
}

/* Dezente Styling für korrekte und falsche Antworten */
.correctAnswer {
  font-size: 1rem;
  font-weight: 400;
  color: #2e7d32; /* Dezentes Grün für korrekte Antwort */
  background-color: #e8f5e9; /* Heller Grünton für Hintergrund */
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.wrongAnswer {
  font-size: 1rem;
  font-weight: 400;
  color: #c62828; /* Dezentes Rot für falsche Antwort */
  background-color: #ffebee; /* Heller Rottton für Hintergrund */
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.questionAndNumberDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.normalNotBlankField {
  font-size: 1rem;
  font-weight: 400;
  color: black; /* Dezentes Rot für falsche Antwort */
  background-color: white; /* Heller Rottton für Hintergrund */
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.answerDivListLap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  overflow: hidden;
}

.answerDivListLap img {
  object-fit: center;
  width: 90%;
  height: auto;
}
.innerAnswerDivListLap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  background-color: #fff;
  color: black;
}

.innerAnswerDivListLap p {
  color: black;
}
