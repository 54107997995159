.select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  border-radius: 8px;
}

.select-container h2 {
  margin-bottom: 20px;
  color: #333;
  font-family: Arial, sans-serif;
}

.checkbox-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  gap: 5px;
}

.checkbox-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  background-color: white;
  margin-bottom: 10px;
  padding: 8px 25px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer; /* Macht den div klickbar */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.checkbox-item.selected {
  background-color: #d1d1d1;
}

.checkedBox {
  background-color: #f44336; /* Standard-Rot für nicht ausgewählt */
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.checkedBox.checked {
  background-color: #4caf50; /* Ändere Hintergrundfarbe, wenn ausgewählt */
}

.select-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-family: Arial, sans-serif;
  transition: background-color 0.3s ease;
}

.select-all-button {
  background-color: #4caf50;
  color: white;
}

.select-all-button:hover {
  background-color: #45a049;
}

.submit-button {
  background-color: #007bff;
  color: white;
}

.submit-button:hover {
  background-color: #0056b3;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.input-container label {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-container input {
  width: 80px;
  height: 30px;
  font-size: 1rem;
  padding: 5px;
  text-align: center;
  border: 2px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}

.input-container input:focus {
  border-color: #2196f3; /* Matching the blue from the progress bar */
}
