/* CatalogDetail.css */

.catalogDetail {
  background-color: #333;
  color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  max-width: 600px;
  min-width: 300px;
  margin: 40px auto;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}

.catalogDetail h2 {
  font-size: 2rem;
  color: #feb47b;
  margin-bottom: 10px;
  margin-top: 15px;
}

.catalogDetail .description {
  font-size: 1rem;
  color: #ddd;
  margin-bottom: 20px;
  line-height: 1.6;
}

.catalogDetail .review {
  font-size: 1.2rem;
  color: #ffdf80;
  font-weight: bold;
  margin-bottom: 20px;
}

.catalogDetail p {
  font-size: 1rem;
  color: #bbb;
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  width: auto;
  margin-top: 2rem;
}

.demo-button,
.buy-button,
.chatButton {
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.chatButton {
  background-color: gray;
  color: #fff;
}

.demo-button {
  background-color: #feb47b;
  color: #fff;
}

.demo-button:hover {
  background-color: #e6835a;
}

.buy-button {
  background-color: #ff7e5f;
  color: #fff;
}

.buy-button:hover {
  background-color: #d96b52;
}

.buy-button:disabled {
  background-color: #ff7e5f;
  color: #fff;
  cursor: not-allowed;
}

.randomReviews {
  margin-top: 20px;
}

.randomReviews h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #f5f5f5;
  text-align: center;
}

.reviewsContainerRandom {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adds spacing between reviews */
}

.reviewCardRandom {
  background: #2c2c2c;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  color: #f5f5f5 !important;
}

.reviewHeaderRandom {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 8px;
  color: #ffa500; /* Highlight username */
}

.reviewCommentRandom {
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #dcdcdc !important;
}

.reviewRatingRandom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px; /* Adds spacing between stars */
}

.star {
  font-size: 1rem !important;
  color: #ffd700; /* Golden star color */
}
