.editCatalog {
  padding: 20px;
  max-width: 500px;
  margin: auto;
  margin-top: 2rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.editCatalog h2 {
  margin-bottom: 20px;
  color: #333;
}

.editCatalogForm .form-group {
  margin-bottom: 15px;
}

.editCatalogForm label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.editCatalogForm input,
.editCatalogForm textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.2s ease;
}

.editCatalogForm input:focus,
.editCatalogForm textarea:focus {
  border-color: #ff8a65; /* Orange border on focus */
}

.editCatalogForm button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  background-color: #ff8a65; /* Main button color */
  color: #fff;
  transition: background-color 0.3s ease;
}

.editCatalogForm button:hover {
  background-color: #e57350; /* Darker shade of orange for hover */
}

.cancelButton {
  background-color: #6c757d !important;
  color: #fff;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.cancelButton:hover {
  background-color: #5a6268 !important;
}

.valid-email {
  transition: none;
  border: 2px solid green !important;
  color: green;
}

.invalid-email {
  transition: none;
  border: 2px solid red !important;
}
