.addQuestionFormDivRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.addQuestionFormDivCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 5px;
}

.addQuestionFormDivCenter label {
  width: 100%;
}

.addQuestionFormDivCenter input {
  max-width: none !important;
}

.uploadedImageDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: auto;
}

.themesDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 15px;
}

.themeDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  margin-bottom: 0px;
  padding: 15px;
  gap: 5px;
  background-color: #333;
  color: white;
}

.themeDiv input {
  max-width: none !important;
}

.themeDiv label {
  width: 100%;
}

.buttonDivForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.buttonDivForm button {
  width: auto;
  height: auto;
  border-radius: 10px;
  background-color: #333;
  color: white;
  border: none;
  transition: ease-in-out 0.1s;
}

.buttonDivForm button:hover {
  scale: 1.1;
  background-color: green;
}

.buttonDivFormEntfernenButton {
  background-color: #aeaeae !important;
  color: black !important;
}

.buttonDivFormEntfernenButton:hover {
  background-color: red !important;
}

.dnd-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.dnd-dropzone {
  border: 2px dashed #3498db;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dnd-dropzone:hover,
.dnd-dropzone.dnd-hover {
  background-color: rgba(52, 152, 219, 0.1);
}

.dnd-input {
  display: none;
}

.uploadedImageDiv {
  margin-top: 20px;
  text-align: center;
}

.uploadedImageDiv img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
}

.dragAndDropForm {
  width: 100%;
}

.blocksContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.blockItem {
  margin: 0 auto;
  padding: 10px;
  background-color: #333;
  color: white !important;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.blockItem label {
  display: block;
  margin-bottom: 5px;
  width: 100%;
}

.blockItem input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: none !important;
}

.buttonDiv button {
  display: block;
  width: auto;
  padding: 10px;
  background-color: #333 !important;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}
.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}

button:hover {
  background-color: #2980b9;
}

.result {
  margin-top: 20px;
  padding: 10px;
  background-color: #eafaf1;
  border-left: 5px solid #2ecc71;
}

.wordPreview {
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
  background-color: #f3f3f3;
  color: black;
  transition: ease-in-out 0.1s;
}

.wordPreview.selected {
  background-color: #333;
  color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
}

.wordsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

/* Allgemeines Styling für das select-Element */
.select-tolerance {
  display: block;
  width: 100%;
  max-width: 200px;
  padding: 10px;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  border: 2px solid #ccc;
  border-radius: 8px;
  margin-top: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-bottom: 10px;
}

/* Hover- und Fokus-Styling */
.select-tolerance:hover {
  background-color: #e8f4ff;
  border-color: #3498db;
}

.select-tolerance:focus {
  outline: none;
  border-color: #2ecc71;
  box-shadow: 0 0 5px rgba(46, 204, 113, 0.5);
}

/* Optional: Styling für Optionen */
.select-tolerance option {
  padding: 10px;
  background-color: #f9f9f9;
  color: #333;
}
