/* Register.css */

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #f9f9f9;
  color: #333;
}

.register-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.register-content h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;
}

.input-field {
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.2s ease;
}

.input-field:focus {
  border-color: #ff8a65;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.registerButton {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  color: #fff;
  background-color: #ff8a65; /* New primary orange */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.registerButton:hover {
  background-color: #e57350; /* Darker shade of orange for hover */
}

.loginLink {
  color: #ff8a65;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bold;
  transition: color 0.2s ease;
}

.loginLink:hover {
  color: #e57350;
}
