/* Login.css */

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  color: #333;
  text-align: center;
}

.login-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.login-content h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 15px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.input-field {
  padding: 12px;
  width: 100%;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.2s ease;
}

.input-field:focus {
  border-color: #ff8a65;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.loginButton {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  font-weight: bold;
  border: 1px solid #ddd;
  border-radius: 8px;
  color: #fff;
  background-color: #ff8a65; /* New orange color */
  cursor: pointer;
  transition: all 0.3s ease;
}

.loginButton:hover {
  background-color: #e57350; /* Darker shade of orange for hover */
}

.registerLink {
  color: #ff8a65;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bold;
  transition: color 0.2s ease;
}

.registerLink:hover {
  color: #e57350;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}
