.chat-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

.notification-badge {
  position: absolute;
  top: -8px;
  right: -20px;
  z-index: 1;
  background-color: red;
  color: white;
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 50%;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
}

/* Allgemeines Styling für Desktop */
nav {
  background-color: #333;
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: relative; /* Füge diese Zeile hinzu */
}

/* Verstecke den horizontalen Overflow */
html,
body {
  overflow-x: hidden;
}

.navbar-container {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7vh;
  max-height: 7vh;
  overflow: hidden;
  padding: 0 2rem;
}

.brand {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.brand a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.brand select {
  background-color: #333;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  max-width: 200px;
}

.brand a {
  border-radius: 50%;
  padding: 0rem;
}

.addIconHover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 7vh;
}

.addIconHover:hover {
  transition: ease-in-out 0.15s;

  scale: 1.1;
  cursor: pointer;
}

.nav-links {
  display: flex;
}

nav ul {
  list-style-type: none;
  display: flex;
  gap: 2rem;
  padding-left: 0;
}

nav ul li {
  color: white;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

button {
  background-color: #555;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

button:hover {
  background-color: #777;
}

/* Hamburger-Icon */
.burger-menu {
  display: none;
  cursor: pointer;
}

.burger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: all 0.3s ease-in-out;
}

/* Mobile Ansicht */
@media screen and (max-width: 768px) {
  .nav-links {
    position: fixed; /* Ändere zu fixed, um das Menü über den ganzen Bildschirm zu legen */
    top: 7vh;
    right: 0;
    width: 100%;
    background-color: #333;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000; /* Stelle sicher, dass das Menü über allem anderen ist */
    overflow-y: auto; /* Ermögliche vertikales Scrollen im Menü */
  }

  .nav-links.open {
    transform: translateX(0);
  }

  nav ul {
    flex-direction: column;
    gap: 1.5rem;
  }

  .burger-menu {
    display: block;
  }

  .bar.open {
    background-color: #ff5252; /* Farbe für das geöffnete Icon */
  }

  /* Hide the hamburger icon when the menu is open */
  .nav-links ul {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .nav-links ul li {
    width: 100%;
  }

  /* Verhindere, dass der Benutzer scrollt, wenn das Menü geöffnet ist */
  body.menu-open {
    overflow: hidden;
  }
}
