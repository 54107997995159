.learnDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.testDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.testResultContainer {
  text-align: center;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f4f6f8;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.resultSummary {
  margin-bottom: 2rem;
}

.resultText {
  font-size: 1.25rem;
  font-weight: 500;
  color: #0073e6;
}

.percentageText {
  font-size: 1.5rem;
  font-weight: 700;
  color: #28a745;
  margin: 0;
}

.incorrectQuestionsContainer {
  margin-top: 2rem;
  text-align: left;
}

.incorrectQuestionsList {
  list-style-type: none;
  padding: 0;
}

.incorrectQuestionItem {
  background-color: #f8d7da;
  color: #721c24;
  margin: 10px 0;
  padding: 10px;
  border-left: 4px solid #f5c6cb;
  border-radius: 4px;
}

.correctAnswerText {
  font-size: 1rem;
  color: #155724;
}

.allCorrectText {
  font-size: 1.25rem;
  font-weight: 500;
  color: #28a745;
  margin-top: 1.5rem;
}

.backButton {
  margin-top: 2rem;
  padding: 10px 20px;
  background-color: #0073e6;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.backButton:hover {
  background-color: #005bb5;
}

.themeAnswerPair {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  color: black;
  gap: 10px;
}

.themeAnswerPair p {
  margin: 0;
}

.lapQuestionContainer {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  gap: 10px;
}

.incorrectAnswerItem {
  background-color: white;
  color: #721c24;
  padding: 10px;
  border-left: 4px solid #f5c6cb;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
