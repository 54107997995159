.createCatalog {
  padding: 20px;
  max-width: 500px;
  margin: auto;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.createCatalog h2 {
  margin-bottom: 20px;
  color: #333;
}

.createCatalogForm .form-group {
  margin-bottom: 15px;
}

.createCatalogForm label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.createCatalogForm input,
.createCatalogForm textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.createCatalogForm button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
}

.cancelButton {
  background-color: #6c757d;
}
