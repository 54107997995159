.chat-page {
  display: flex;
  flex-direction: column;
  height: 93vh;
  background-color: #e5ddd5;
  font-family: Arial, sans-serif;
  width: 100%;
  max-width: 500px;
}

.chat-page-outer-div {
  display: flex;
  flex-direction: column;
  height: 93vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.chat-header {
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  color: white;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-header button {
  position: absolute;
  background-color: none;
  background: none;
  left: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-header a {
  color: #333;
}

.chat-messages {
  flex: 1;
  padding: 15px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.chat-message {
  max-width: 70%;
  padding: 10px;
  border-radius: 10px;
  font-size: 0.95em;
  position: relative;
}

.sent {
  align-self: flex-end;
  background-color: #333;
  border-top-right-radius: 0;
}

.received {
  align-self: flex-start;
  background-color: white;
  border-top-left-radius: 0;
}

.received p {
  color: #333;
}

.sent p {
  color: white;
}

.message-sender {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 0.85em;
}

.message-text {
  margin: 0;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #333;
  border-top: 1px solid #ddd;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 1em;
  outline: none;
}

.chat-input button {
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1em;
}

.chat-input button:hover {
  background-color: #064b48;
}

.sendRequestDiv,
.requestDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  background-color: white;
  border-radius: 10px;
  position: static;
  width: 95%;
  bottom: 5px;
}

.sendRequestDiv,
.requestDiv button {
  background: #333;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1em;
  transition: ease-in-out 0.15s;
}

.sendRequestDiv,
.requestDiv button:hover {
  background-color: #5d5d5d;
  scale: 1.05;
}

.sendRequestDiv,
.requestDiv p {
  font-size: 0.85em;
  color: #333 !important;
}

.sendRequestDiv p {
  color: white !important;
}

.no-messages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  background-color: #333;
  border-radius: 10px;
  padding: 10px;
}

.no-messages h4 {
  color: rgb(183, 183, 183) !important;
}

.unauthorized-message {
  background-color: red;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (min-width: 800px) {
  .chat-page {
    height: 91vh;
    border-radius: 15px;
    overflow: hidden;
    width: 50%;
    min-width: 380px;
    max-width: none;
  }

  .chat-page-outer-div {
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
}
