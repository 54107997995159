.catalogPage {
  padding: 40px 20px;
  max-width: 1200px;
  margin: auto;
  text-align: center;
}

.catalogPage h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
  font-weight: 700;
  letter-spacing: 1px;
}

.searchField input {
  padding: 15px;
  width: 100%;
  max-width: 600px;
  font-size: 1rem;
  margin-bottom: 30px;
  border: none;
  border-radius: 50px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #333, #202020);
  color: white;
}

.searchField input:focus {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

.catalogList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
}

.catalogCard {
  background: linear-gradient(135deg, #666, #333);
  border-radius: 15px;
  padding: 25px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.priceTag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ffd700;
  color: black !important;
  padding: 5px 10px;
  border-radius: 2px;
  border-top-right-radius: 15px;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.catalogCard:hover {
  transform: translateY(-10px);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.15);
}

.catalogCard h3 {
  font-size: 1.8rem;
  margin: 0 0 10px;
  color: #feb47b; /* Soft orange to match the theme */
  font-weight: 600;
}

.catalogCard .description {
  font-size: 1rem;
  color: white;
  line-height: 1.6;
  margin-bottom: 15px;
}

.catalogCard .review {
  font-size: 1rem;
  color: #ffcc00;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
}

.catalogCard .review span {
  font-size: 1.5rem;
  color: #ffd700;
}

.backButtonCatalog {
  position: fixed;
  bottom: 20px;
  background-color: #333;
  padding: 10px 25px;
  border-radius: 5px;
  transition: all 0.1s ease;
}

.backButtonCatalog:hover {
  background-color: #333;
  scale: 1.03;
  bottom: 22px;
}

.purchasedCatalogs {
  margin-bottom: 30px;
  text-align: left;
}

.purchasedCatalogs h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
}

.purchasedCatalogList {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.purchasedCatalogCard {
  background: #f1f1f1;
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.purchasedCatalogCard:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
}

.demoCatalogs {
  margin-bottom: 30px;
  text-align: left;
}

.demoCatalogs h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
}

.purchasedCatalogList {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.purchasedCatalogCard {
  background: #f1f1f1;
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.purchasedCatalogCard:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
}
