.fillInTheBlanksQuestion {
  margin: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.questionImageContainer {
  text-align: center;
}

.questionImage {
  max-width: 100%;
  height: auto;
}

.fillInTheBlanksContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.blankInput {
  border: 2px solid #ccc;
  padding: 5px;
  margin: 0 5px;
  min-width: 50px;
}

.correctAnswer {
  margin-top: 20px;
  color: green;
}

.submitButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #0056b3;
}
