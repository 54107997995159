.addQuestionsOuterDiv {
  padding: 20px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.addQuestionsOuterDivDropdown {
  flex-direction: column;
}

.addQuestions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 901px;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  background-color: #ff7e5f;
}

.addQuestionsNotDropedDown {
  width: 285px;
  height: 285px;
}

.addQuestionsNotDropedDown:hover {
  transition: ease-in-out 0.15s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  scale: 1.01;
}

.addQuestions h2 {
  color: #333;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.addQuestions p {
  color: #ff4c4c;
  margin-bottom: 20px;
  font-size: 16px;
}

.addQuestionsDropdown {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 0.2s;
  margin: 0;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background: #333;
  color: #eee;
}

.addQuestionsDropdown h2 {
  color: #eee !important;
}

.addQuestionsDropdown:hover {
  margin-top: 1rem;
  color: #ff7e5f;
}

.addQuestionsDropdownDropedDown {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.addQuestionsDropdown:active {
  transition: ease-in-out 0.1s;

  scale: 0.9;
  margin-top: 1rem;
}

.addQuestionsDropedDown {
  width: 100%;
  padding: 20px;
  background-color: white;
  overflow: hidden;
}

.topSelection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.topSelection div {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.topSelection label {
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: 500;
}

.topSelection select,
input {
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.topSelection select:focus,
input:focus {
  border-color: #007bff;
  outline: none;
}

form {
  width: 100%;
}

form div {
  width: 100%;
}

form label {
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: 500;
  display: block;
}

form input[type='text'],
form input[type='number'],
form input[type='file'] {
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  max-width: none;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

form input:focus {
  border-color: #007bff;
  outline: none;
}

form button {
  padding: 12px 25px;
  width: 100%;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

form button:hover {
  background-color: #0056b3;
}

form .radio-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

form .radio-group label {
  display: flex;
  align-items: center;
  font-size: 15px;
}

form .radio-group input {
  margin-right: 5px;
}

form .answers {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.answers div {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.answers input[type='text'] {
  flex: 1;
}

p {
  font-size: 15px;
  color: #007bff;
}

.categoryDropdown {
  width: 100%;
  padding: 10px;
  border-radius: 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 2rem;
}

.categoryDropdown p {
  color: black !important;
}

.addQuestionsTopSelectionRowDiv {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.addQuestionsTopSelectionRowDiv div {
  display: flex;

  width: 50%;
}

@media screen and (max-width: 600px) {
  .addQuestionsTopSelectionRowDiv {
    flex-direction: column;
  }
}

/* Pulsierende Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 0.5s ease-in-out;
}

/* Add this to your CSS file */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  .addQuestionsNotDropedDown {
    width: 100%;
    height: auto;
  }
}
