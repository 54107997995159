.categorie {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  transition: ease-in-out 0.25s;
}

.categorie:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}

.categorie:active {
  transition: ease-in-out 0.05s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}

.categorieStats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.categorieStatsButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
}

.categorieStatsButton button {
  height: 36px;
  width: 36px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 0.15s;
}

.categorieStatsButton button:hover {
  cursor: pointer;
  background-color: #333;
  scale: 1.15;
}

.categorieStatsButton button:active {
  transition: ease-in-out 0.05s;
  scale: 1;
  background-color: black;
}

.categorieStats h3 {
  margin: 0;
  padding: 0;
}

.categorieStats p {
  margin-bottom: 0;
}

.categories {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 20px;
  padding-bottom: 2rem;
}

.learn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.infoDivLearn {
  position: fixed;
  bottom: 10px;
  left: 50%; /* Positionierung in der horizontalen Mitte */
  transform: translateX(-50%); /* Zentriert das Element genau in der Mitte */
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.infoDivLearnActive {
  background-color: #ff7e5f;
  padding: 10px;
  padding-bottom: 0;
  border-radius: 10px;
}
