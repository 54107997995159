.dndQuestion {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  max-width: 600px;
  width: 100%;
  margin-bottom: 10px;
}

.dndQuestion:hover {
  background-color: #f1f1f1;
}

.dndContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.dndBlock {
  padding: 15px;
  background-color: #ffffff;
  border: 2px solid #3498db;
  border-radius: 8px;
  cursor: grab;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  font-weight: 600;
  color: #2c3e50;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dndBlock:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.correctAnswer {
  margin-top: 20px;
  padding: 15px;
  border-top: 3px solid #2ecc71;
  color: #27ae60;
  background-color: #eafaf1;
  border-radius: 8px;
}

.correctAnswer ul {
  list-style: none;
  padding-left: 0;
}

.correctAnswer li {
  font-weight: bold;
  margin: 5px 0;
}

.questionImageContainer {
  margin: 20px 0;
  text-align: center;
}

.questionImage {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  border: 2px solid #3498db;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.submitButton {
  display: inline-block;
  padding: 12px 25px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submitButton:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.submitButton:active {
  background-color: #1c6691;
  transform: translateY(0);
}
