/* styles/Profile.css */

.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: #f5f5f5;
  height: 93vh;
  width: 100%;
}

.profile-content {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  text-align: center;
}

.profile-content h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333333;
}

.profile-info p {
  margin: 10px 0;
  font-size: 1rem;
  color: #666666;
}

.profile-info strong {
  color: #333333;
}

.profile-actions {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.logout-button,
.delete-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.logout-button {
  background-color: #2196f3;
  color: #ffffff;
}

.logout-button:hover {
  background-color: #1976d2;
}

.delete-button {
  background-color: #e57373;
  color: #ffffff;
}

.delete-button:hover {
  background-color: #d32f2f;
}
