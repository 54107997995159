.editCategories {
  border-radius: 10px;

  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h2 {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 30px;
}

.addCategory {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 600px;
}

.addCategory h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #444;
}

.addCategory input[type='text'] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 70%;
  font-size: 1rem;
  margin-right: 10px;
}

.addCategory input[type='color'] {
  padding: 0;
  border: none;
  width: 70%;
  height: 40px;
  cursor: pointer;
  margin-right: 10px;
}

.addCategory button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.addCategory button:hover {
  background-color: #0056b3;
}

.categoryList h3 {
  font-size: 1.5rem;
  color: #444;
  margin-bottom: 20px;
}

.categoryItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  width: 100%;
}

.categoryItem p {
  margin: 0 !important;
  font-size: 1rem !important;
  color: #333 !important;
}

.categoryItem input[type='text'] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 60%;
  font-size: 1rem;
  margin-right: 10px;
}

.categoryItem input[type='color'] {
  padding: 0;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-right: 10px;
}

.categoryItem button {
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.categoryItem button:hover {
  background-color: #218838;
}

.categoryItem .editButton {
  background-color: #007bff;
}

.categoryItem .editButton:hover {
  background-color: #0056b3;
}
