.chat-selection-page {
  display: flex;
  flex-direction: column;
  height: 93vh;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.chat-header {
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  color: white;
  padding: 15px;
  font-weight: bold;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.chat-selection-page h2 {
  margin: 10px;
  margin-top: 20px;
}

.chat-header button {
  position: absolute;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1.2em;
  display: flex;
  align-items: center;
}

.chat-header button:hover {
  color: #ffd1b3;
}

.chat-list {
  flex: 1;
  padding: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.chat-item {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
  width: 100%;
  max-width: 500px;
}

.chat-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px #ff7e5f;
}

.chat-link {
  text-decoration: none;
  color: #333;
  display: flex;
  flex-direction: column;
}

.chat-info {
  display: flex;
  flex-direction: column;
}

.chat-catalog {
  font-weight: bold;
  font-size: 1.1em;
  color: #333;
  margin: 0;
  margin-bottom: 5px;
}

.chat-participants {
  font-size: 0.9em;
  color: #666;
  margin: 0;
}

.chat-last-message {
  font-size: 0.9em;
  color: #ff7e5f;
  margin: 0;
}

.unreadBox {
  width: 20px;
  height: 20px;
  background-color: #ff7e5f;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
