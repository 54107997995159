.editQuestionKomponent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.editQuestionKomponent form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.editQuestionKomponent form input {
  width: 100% !important;
  border: none;
}

.editQuestionKomponentSubmitButton {
  width: 100%;
  margin-top: 10px;
  background-color: #333;
  color: white;
  margin-bottom: 5px;
}

.deleteButtonEditQuestions {
  background-color: red;
  border-radius: 5px;
}
