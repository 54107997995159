.categoryContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 93vh;
  padding: 10px;
  padding-top: 2rem;
}

.buttonDivLearn {
  position: fixed;
  bottom: 20px; /* Adjust the bottom position as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust to truly center */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.buttonDivLearn button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: black; /* Button background color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.categoryContent h2 {
  margin: 0;
}

.questionItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feedbackDisplay {
  width: 100%;
  max-width: 600px;
}

.feedbackIndicationDiv.correct {
  background-color: green;
}

.feedbackIndicationDiv.incorrect {
  background-color: red;
}

.feedbackIndicationDiv {
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
  color: white;
  padding: 10px;
  width: 100%;
  opacity: 0;
  transform: translateY(-10px);
  animation: fadeInSlideUp 0.5s ease-in-out forwards; /* Add animation */
}

/* Animation for the feedback indicator */
@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(-10px); /* Start a bit lower */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Slide up to the original position */
  }
}

.feedbackStreakDiv {
  font-size: 1.5rem; /* Larger font for visibility */
  margin-top: 15px;
  width: 100%;
  max-width: 600px;
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  padding: 15px;
  border-radius: 10px; /* Softer, larger border radius */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s ease-in-out; /* Fade-in animation */
  transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
}

.feedbackStreakDiv:hover {
  transform: scale(1.05); /* Slight zoom on hover for interactivity */
  background: linear-gradient(
    90deg,
    #0072ff,
    #00c6ff
  ); /* Reverse gradient on hover */
}

.feedbackStreakDiv p {
  margin: 0;
  color: white;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px; /* Slight letter spacing for modern look */
}

/* Animation for fade-in effect */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.95); /* Start slightly smaller */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* Grow to full size */
  }
}

/* multiple choice question */

.multipleChoiceQuestion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

.questionTitle {
  font-size: 1.2rem;
  margin-bottom: 16px;
}

.multipleChoiceForm {
  width: 100%;
}

.answerLabel {
  color: black; /* Standardfarbe */
}

.answerLabel.correct {
  color: green; /* Farbe für die richtige Antwort */
  font-weight: bold; /* Optional: macht die richtige Antwort fetter */
}

.answerLabel.wrong {
  color: red; /* Farbe für die falsche Antwort */
  font-weight: bold; /* Optional: macht die falsche Antwort fetter */
}

.answerOption {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.answerInput {
  margin-right: 8px;
}

.answerLabel {
  font-size: 1rem;
}

.submitButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 16px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 16px;
}

.submitButton:hover {
  background-color: #0056b3;
}

.questionImageContainer {
  text-align: center;
  margin-bottom: 15px;
}

.questionImage {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* text question */
.correctAnswerDisplay {
  margin-top: 10px;
  padding: 8px;
  background-color: #e8f5e9; /* Light green background */
  color: #388e3c; /* Green text */
  border-radius: 5px;
  font-weight: bold;
  border: 1px solid #388e3c; /* Border to match text color */
}

.textQuestion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

.questionTitle {
  font-size: 1.2rem;
  margin-bottom: 16px;
}

.textQuestionForm {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.textInput {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: none !important;
  margin-bottom: 16px;
}

.submitButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 16px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #0056b3;
}
.questionImageContainer {
  text-align: center;
  margin-bottom: 15px;
}

.questionImage {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
