.stats {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding-bottom: 100px;
}

.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.innerDashboard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.wrongQuestionList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.wrongQuestion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  border-radius: 5px;
  gap: 5px;
  background-color: #333;
  min-height: 45px;
}

.wrongQuestionInnerDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  flex-wrap: wrap;
  padding-left: 10px;
}

.wrongQuestion p {
  margin: 0;
  height: 100%;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: black;
}

.wrongQuestionIndicator {
  width: 20%;
  padding: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: black;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.testResultsList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.testResult {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 10px;
  background-color: #333;
  border-radius: 5px;
  color: white;
  min-height: 45px;
}

@media screen and (max-width: 600px) {
  .testResult {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .testResult h5 {
    background-color: #3f3f3f;
    margin: 0;

    padding: 10px !important;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
  }

  .testResult p {
    background-color: #3f3f3f;

    margin: 0;
    padding: 10px !important;

    font-weight: bold;

    width: 100%;
  }

  .testResult div {
    background-color: #3f3f3f;
    padding: 0 !important;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    width: 100%;
  }
}

.testResultInnerDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.testResult h5 {
  margin: 0;

  padding: 4px;
}

.testResult p {
  margin: 0;
  padding: 4px;

  font-weight: bold;
}

.testResult div {
  padding: 4px;
}
