.loginButton {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.registerButton {
  background-color: rgb(238, 238, 238);
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: black;
}

.registerButton a {
  color: black;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

@media screen and (max-width: 600px) {
  input,
  textarea,
  select {
    font-size: 16px;
  }
}

input,
textarea {
  font-size: 16px;
  touch-action: manipulation; /* iOS touch handling */
}
