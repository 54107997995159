.dashboard {
  text-align: center;
  padding: 2rem;
  background-color: #f7f9fc;
  min-height: 100vh;
  height: auto;
  padding-bottom: 80px;
}

.innerDashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.streakContainer {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  padding: 1rem 2rem;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.streakContainer:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.streakIcon {
  color: #ffffff;
  font-size: 2.5rem;
  margin-right: 0.5rem;
}

.streakText {
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
}

.membershipPrompt {
  text-align: center;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.introText {
  font-size: 1rem;
  color: #666;
  margin: 10px 0 20px;
}

.benefitsList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  margin: 20px 0;
}

.benefitItem {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  padding: 10px;
}

.benefitIcon {
  color: #4caf50;
  font-size: 1.5rem;
  margin-right: 10px;
}

.upgradeButton {
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upgradeButton:hover {
  background-color: #1976d2;
}

.addCatalogButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  color: black;
  width: auto;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 1rem;
  transition: ease-in-out 0.15s;
}

.addCatalogButton:hover {
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  color: black;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.addCatalogButton:active {
  transform: scale(1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.addCatalogButton a {
  color: black;
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;
}

.addCatalogDashboardButton {
  position: fixed;
  bottom: 20px;
  background-color: #333;
  border-radius: 10px;
  color: white;
  padding: 11px;
  transition: ease-in-out 0.1s;
  text-decoration: none;
}

.addCatalogDashboardButton:hover {
  bottom: 25px;
  background-color: black;
  padding: 14px;
}

.addCatalogDashboardButton:active {
  transition: none;
  scale: 0.9;
  background-color: #333;
}
